import {Component, createRef} from 'react';
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import ErrorBlock from "../ErrorBlock";
import {CAjaxPoll} from "./ajax_poll";
import {IBlockComponentProps} from "../_Block";
import {DefaultController} from "../../../../backends/YiiRoutes";
import {StoreContext} from "../../../../stores/StoreLoader";
import {isJson} from "../../../admin/spinup/utilities";
import styles from "./styles/PollBlock.module.scss";

interface IPollBlockProps extends IBlockComponentProps {
    blockObj: IPollBlockObj
}

class PollForm extends Component<{
    question: string,
    tid: string,
    answers: string[],
    blockObj: IPollBlockObj,
}, { loading: boolean }> {
    private ajax_poll;
    private blockRef;

    static contextType = StoreContext;

    constructor(props) {
        super(props);

        this.blockRef = createRef();

        this.state = {
            loading: false,
        }
    }

    setLoading = (value: boolean) => {
        this.setState({
            loading: value,
        })
    }

    componentDidMount() {
        require("blockui/jquery.blockUI"); //
        this.ajax_poll = new CAjaxPoll(this.blockRef.current, this.setLoading);
        this.ajax_poll.run();
    }

    render() {
        try {
            const {i18nStore, userStore} = this.context;
            const blockObj = this.props.blockObj;

            return <>
                {this.state.loading && <div className={styles.loading} />}
                <form ref={this.blockRef} className='ajax-poll-form' data-tid={this.props.tid}
                      data-userid={userStore.id}
                      action={DefaultController.blockCommand(i18nStore.currentOrgSupportedLocale, blockObj.id)}>
                    <fieldset>
                        <legend className="sr-only">{this.props.question}</legend>
                        <div className="panel-body">
                            <ul className="list-group">
                                {this.props.answers && this.props.answers.map((answer, key) => {
                                    return <div key={key} className="list-group-item ajax-poll-item" data-tid={key}>
                                        <div className="radio">
                                            <input title="Poll answer" className='ajax-poll-item-radio' type='radio'
                                                   name={"_"} id={`answer_${key}${blockObj.id}`}/>
                                            <label aria-labelledby={`answer_${key}${blockObj.id}`}>
                                                {answer}
                                            </label>
                                        </div>
                                        <div className='ajax-poll-item-stats-box'>
                                            <div className='ajax-poll-item-bar'/>
                                            <span className='ajax-poll-item-count'/>
                                            <span className='ajax-poll-item-perc'/>
                                        </div>
                                    </div>
                                })}
                            </ul>
                        </div>
                        <div className='text-center ajax-poll-vote-box'>
                            <button type="button"
                                    className="btn ajax-poll-btn-view sb-organization-color-element-bg">View
                            </button>
                            {userStore.id && <>
                                <button type="button"
                                        className="btn ajax-poll-btn-vote sb-organization-color-element-bg">Vote
                                </button>
                                <br/>
                            </>}
                        </div>

                        <div className='ajax-poll-back-box' style={{"display": "none"}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="button"
                                           className="btn ajax-poll-btn-back sb-organization-color-element-bg"
                                           value="&lt;&nbsp;Back "/>
                                </div>
                                <div className="col-md-6">
                                    <div className='ajax-poll-total-box'>
                                        <span className='ajax-poll-total-caption'>Total:</span>
                                        <span className='ajax-poll-total-value'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </>
        } catch (_e: unknown) {
            const e: Error = _e as Error;
            return <ErrorBlock blockObj={{errorMessage: e}}/>
        }
    }
}

export default function PollBlock(props: IPollBlockProps) {
    try {
        const blockObj = props.blockObj;

        let json_data = props.blockObj?.blockModel?.jsonObj ? props.blockObj.blockModel.jsonObj : props.blockObj.blockModel?.json_data
        if (typeof json_data === 'string' && isJson(json_data)) json_data = JSON.parse(json_data)
        const question = json_data?.block?.settings?.question || "";
        const answers = json_data?.block?.settings?.answers || [];
        const tid = `${blockObj.title}_${question}_${answers.join("")}`;

        return <BlockScroller className="sb-block-poll"
                              blockRef={props.blockRef}
                              blockObj={props.blockObj}
                              htmlId={props.htmlId}
                              setShowExpandButton={props.setShowExpandButton}
                              outsideGrid={props.outsideGrid}>
            <BlockHead className="sb-block-poll" blockType={blockObj.blockType} editableTitle={question}
                       blockId={blockObj.id}/>
            <div className={`sb-blockContent scroll-${tid}`}>
                <PollForm {...props} question={question} answers={answers} tid={tid}/>
            </div>
        </BlockScroller>
    } catch (_e: unknown) {
        const e: Error = _e as Error;
        return <ErrorBlock blockObj={{errorMessage: e}}/>
    }
}
